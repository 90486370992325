<template>
  <div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="box box-menu is-paddingless">
          <aside class="menu-page menu">
            <ul class="menu-list">
              <li>
                <a
                  @click="setActiveMenu('languages')"
                  :class="{ 'is-active': activeMenu === 'languages' }"
                  >Languages</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('mailtexts')"
                  :class="{ 'is-active': activeMenu === 'mailtexts' }"
                  >Mail texts</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('BookingTerm')"
                  :class="{ 'is-active': activeMenu === 'BookingTerm' }"
                  >Booking terms</a
                >
              </li>

              <li>
                <a
                  @click="setActiveMenu('PrivacyPolicy')"
                  :class="{ 'is-active': activeMenu === 'PrivacyPolicy' }"
                  >Privacy terms</a
                >
              </li>
            </ul>
          </aside>
        </div>
      </div>
      <div class="column">
        <div v-if="activeMenu === 'languages'">
          <ChannelLanguages />
        </div>

        <div v-if="activeMenu === 'mailtexts'">
          <MailTexts />
        </div>

        <div v-if="activeMenu === 'BookingTerm'">
          <Terms :termsType="'BookingTerm'" :key="'bookingTerms'" />
        </div>

        <div v-if="activeMenu === 'PrivacyPolicy'">
          <Terms :termsType="'PrivacyPolicy'" :key="'privacyTerms'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    ChannelLanguages: () => import('@/components/Settings/ChannelLanguages'),
    MailTexts: () => import('@/components/Settings/MailTexts'),
    Terms: () => import('@/views/Terms/Terms'),
  },

  props: {},

  data() {
    return {
      activeMenu: 'languages',
    }
  },

  computed: {},

  created() {},

  methods: {
    setActiveMenu(item) {
      this.activeMenu = item
    },
  },
}
</script>

<style></style>
